.App {
  text-align: left;
  max-width:1280px;
  margin:auto;
  min-height:100vh;
  padding:10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* @import "mobstyle.css" screen and (max-width: 768px); 
    Import the "mobstyle.css" style sheet ONLY if the media is screen and the viewport is maximum 768 pixels: */
/*@import "02_hyperTreeReset.css" ; */
@page { size: 8.66in 11in; margin: 0.25in }
.page {
  position:relative;
}
.page-wrapper {
  max-width: 960px;
  margin: auto;
}

.action {
    padding:10px;
    cursor:pointer;
    font-size:16px;
    
}

.MuiChip-root {
  padding:10px !important;
  cursor:pointer !important;
}

.trashicon {
  margin:0 !important;
  background-color:transparent !important;
}

.trashicon .MuiChip-label {
  display:none;
}

.trashicon .MuiSvgIcon-root {
  margin:0 !important;
  color:#dfdfdf !important;
}

.trashicon:hover .MuiSvgIcon-root {
  margin:0 !important;
  color:red !important;
}

.profilePic {
    position:relative;
    border-radius: 10px;
    width: 50vw;
    min-width: 100px;
    overflow:hidden;
    max-width:480px;
    max-height:460px;
}
.profilepic img{
    border-radius: 10px;
    min-height: 100%;
    object-fit: cover;
        width: 100%;
    height: 100%;
}

.profilepic .upload_outline {
        position: relative;
    max-width: 100%;
    background-color: #e6fbff6e;
    margin: 30px;
    border-radius: 10px;
    border: 2px dotted black;
    min-height: 200px;
}

.profilepic .overlaytext {
        position: absolute;
        width:100%;
        height:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:18px;
        font-weight:bold;
        color:#585858;
        z-index:5;
        text-align:center;
        text-shadow:0 2px 4px white;
}

.profilepic .dzu-dropzone {
  position: absolute;
    z-index: 9;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    border: none;
    margin: 30px;
    max-width: 100%;
    width:auto;
}

.profilepic .dzu-dropzone .dzu-inputLabel{
   text-align:center;
   margin:20px;
   color:white;
   text-shadow:0 2px 4px black;
}

.profilepic .dzu-dropzone .dzu-previewContainer {
    
    position: relative;
   
    z-index: 1;
    width: 100%;
    display: block;
    margin: auto;
    border:none;
   padding:0;
}

.profilepic .dzu-dropzone .dzu-previewContainer .dzu-previewImage {
   width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.profilepic .dzu-dropzone .dzu-previewContainer .dzu-previewStatusContainer {
  position: absolute;
    top: 0;
    right: 0;
}

.media-upload .dzu-dropzone {
  overflow:hidden;
  padding-bottom:10px;
}


.description, .association {
  margin:20px;
}

.associations {
  display:flex;
  flex-direction:row;
}

.assoc_card {
  min-height:100px;
  max-width:200px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
}

.assoc_card.new {
  background-color:lightblue;
}

.member-page .profilePic {
    width:100%;
    object-fit:cover;
    border-radius:10px;
}






img {
  display: block;
  max-width:100%;
  max-height:100%;
  width: auto;
  height: auto;
  }

.name {	
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-wrap: nowrap;
  font-size:16px;
  color:#ff00ff;
}

.name p{	
  font-size: 14pt; font-style: normal;
  font-weight: normal;
  margin-left: 5px;
  flex-wrap: nowrap;
}

em { 
  font-size: 10pt; font-style: normal;
  font-weight: normal 
}

p { 
	font-size: 12pt; 
	font-style: normal;
	font-weight: normal 
}

.regular { 
	font-size: 12pt; 
	font-style: normal;
	font-weight: normal;
	text-decoration:none;
	color:black;
	padding:20px;
	border:1px solid black;
	border-radius:6px;
	display:block;
  }

.listinfo {
  font-size: 14px;
  
}

.listinfo span{
  font-weight:bold;
}
  

.H1 { 
  font-size: 14pt; 
  font-style: normal;
  font-weight: bold ;
  margin-top: 1px ;
  margin-bottom: 1px ;
}
.famNote { 
  font-size: 12pt; 
  font-style: normal;
  font-weight: normal;
  margin-top: 1px ;
  margin-bottom: 1px ;

		  }
.h1 { 
  font-size: 12pt; 
  font-style: normal;
  font-weight: bold ;
  margin-top: 1px ;
  margin-bottom: 1px ;
}
      
