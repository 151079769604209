.appnav {
 display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    background-color: darkgray;
    align-items: center;
}

.title {
    font-size: 22px;
    font-weight: bold;
    padding: 10px;
    color:white;
}

.subnav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom:20px;
}

